import * as React from 'react'
import { Flower } from './icons'
import styled from 'styled-components'

const Styled = styled.div`
  position: relative;
  width: 128px;
  height: 128px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transform: rotate(37deg);

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translate3d(0, 0, 0) rotate(0);
    will-change: transform;
    overflow: visible;
    color: var(--fl-color-bg, ${({ backgroundColor }) => backgroundColor});
  }

  div {
    position: relative;
    padding: 0 24px;
    font-size: 23px;
    color: var(--fl-color-text, ${({ textColor }) => textColor});
  }
`

function FeatureLabel({
  text,
  textColor = 'var(--color-cookie)',
  backgroundColor = 'var(--color-red)',
  border = false,
}: {
  text: string
  backgroundColor?: string
  textColor?: string
  border?: boolean
}) {
  return (
    <Styled textColor={textColor} backgroundColor={backgroundColor}>
      <Flower border={border} />
      <div className="type-m-sp-xl">{text}</div>
    </Styled>
  )
}

export { FeatureLabel }
